<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex flex-wrap justify-content-between mt-2">
        <div class="d-flex flex-wrap">
          <div>
            <h4>{{ $t("backoffice.data-manager.title") }}</h4>
            <p>{{ $t("backoffice.data-manager.subtitle") }}</p>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <b-row v-if="enabledAppsWithExport && enabledAppsWithExport.length > 0" class="mt-2">
          <b-col
            v-for="(app, index) in enabledAppsWithExport"
            :key="index"
            cols="12"
            md="6"
            lg="4"
            xl="3"
            class="mb-2"
          >
            <b-card
              header-bg-variant="light"
              style="height: 260px"
            >
              <template #header>
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="d-flex flex-wrap">
                    <h4> {{ getModelName(app.name) }}</h4>
                  </div>
                </div>
                <b-button 
                  variant="link" 
                  class="p-0 mb-25" 
                  @click=" openModal(app)"
                >
                  <feather-icon icon="DownloadIcon" variant="primary" size="20" />
                </b-button>
              </template>
              <b-card-text class="y-scroll-types-container mt-1">
                {{ 'Descargar datos en CSV' }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <!-- Container without data: Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col v-if="placeholder" cols="12">
            <img :src="placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t('available.message', { itemName: $t('backoffice.data-manager.data') }) }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Export Modal -->
    <b-modal
      id="export-modal"
      :title="$t('backoffice.data-manager.export')"
      :ok-title="$t('backoffice.data-manager.download')"
      ok-only
      no-close-on-backdrop
      @ok="downloadCsvReport()"
    >
      <!-- App title -->
      <h4 style="border-bottom: 1px solid lightgrey; margin-bottom: 1rem;">
        {{ appName }}
      </h4>

      <b-form>

        <!-- Fields to export -->
        <label style="font-weight: 700;">
          {{ 'Fields to export' }}
        </label>

        <b-form-group v-if="compulsoryExportOptions[exportForm.appKey]">
          <!-- Compulsory options -->
          <div v-for="option in compulsoryExportOptions[exportForm.appKey]" :key="option">
            <div style="display: inline-flex;">
              <b-form-checkbox
                v-model="defaultChekOption"
                class="mb-1 ml-50"
                disabled
              />
              <span>
                {{ option }}
              </span>
            </div>
          </div>
          <!-- Optional options -->
          <div 
            v-for="option in selectExportOptions[exportForm.appKey]" 
            :key="option"
          >
            <div style="display: inline-flex;">
              <b-form-checkbox
                v-model="exportOptions[exportForm.appKey][option.value]"
                class="mb-1 ml-50"
              />
              <span>
                {{ option.label }}
              </span>
            </div>
          </div>
        </b-form-group>

        <div v-else>
          {{ $t('all') }}
        </div>

        <div v-if="exportForm.appKey === 'members'">
          <!-- Filters -->
          <label style="font-weight: 700;">
            {{ 'Filter by' }}
          </label>

          <b-form-group>
            <label for="filter-by-status" class="font-weight-bold">
               {{ 'Status' }}
              </label>
            <v-select
              id="filter-by-status"
              v-model="filterByStatus"
              :placeholder="'Select a status...'"
              label="label"
              :reduce="(a) => a.value"
              :options="memberStatusOptions"
            />
          </b-form-group>
        </div>

      </b-form>
    </b-modal>
  </backoffice-container>
</template>

<script>
//import { TypeMorphTranslated } from '@copernicsw/community-common';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: 'Exports',
  components: {
    BackofficeContainer,
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      exportForm: {
        appKey: null,
      },
      appName: '',
      isLoading: false,
      appsWithExport: [
        'members',
        'organizations',
        'societies',
        'tasks',
        'meetings',
        'forum',
        'blogs',
        'votes',
        'societies',
        'events',
        'projects',
        'challenges',
        'sections',
        'subcommunities',
        'services',
        // 'payments',
        // 'transactions',
        // 'shipments',
        'votes'
      ],
      featuresWithExport: [
        'likes',
        'forms-responses',
        'onboarding-responses'
      ],
      endpoints: {
        'members': 'backoffice/members/export',
        'tasks': 'tasks/export',
        'meetings': 'backoffice/meetings/export',
        'forum': 'forums/export',
        'blogs': 'blogs/export',
        'likes': '',
        'forms-responses': 'backoffice/forms/exportResponses',
        'onboarding-responses': 'backoffice/onboarding/exportAnswers',
        'societies': 'backoffice/organizations/export',
        'organizations': 'spaces/export', // requiere mainTypeID
        'events': 'spaces/export', // requiere mainTypeID
        'projects': 'spaces/export', // requiere mainTypeID
        'challenges': 'spaces/export', // requiere mainTypeID
        'sections': 'spaces/export', // requiere mainTypeID
        'subcommunities': 'spaces/export', // requiere mainTypeID
        'services': 'spaces/export', // requiere mainTypeID
        'courses': 'spaces/export', // requiere mainTypeID
        'votes': 'backoffice/voting/exportAnswers',
        'payments': 'backoffice/payments/export',
        'transactions': 'backoffice/transactions/export',
        'shipments': 'backoffice/shipments/export'
      },
      modelTypes: {
        'events': 1,
        'courses': 2,
        'projects': 3,
        'sections': 6,
        'subcommunities': 7,
        'organizations': 9,
        'challenges': 10,
        'services': 11
      },
      // Otros endpoints
      // backoffice/members/export/onsite
      // backoffice/members/export/online
      selectExportOptions: {
        members: [
          { value: 'Member_Number', label: 'Member Number' },
          { value: 'Headline', label: 'Headline' },
          { value: 'Summary', label: 'Summary' },
          { value: 'Status', label: 'Status' },
          { value: 'Status_id', label: 'Status ID' },
          { value: 'modality', label: 'Modality' },
          { value: 'mainOnboardingResponses', label: 'Main Onboarding Responses' },
          { value: 'currentSpaceOnboardingResponses', label: 'Current Space Onboarding Responses' },
          { value: 'classifiers', label: 'Classifiers' },
          { value: 'customfields', label: 'Customfields' },
          { value: 'internalSpaces', label: 'Internal Spaces' },
          { value: 'QR_Link', label: 'QR Link' },
          { value: 'Created_at', label: 'Created at' },
          { value: 'Updated_at', label: 'Updated at' },
        ]
      },
      compulsoryExportOptions: {
        members: [
          'Email',
          'Name',
          'Surname',
        ]
      },
      defaultChekOption: true,
      exportOptions: {
        members: {
          modality: false,
          mainOnboardingResponses: false,
          currentSpaceOnboardingResponses: false,
          classifiers: false,
          customfields: false,
          internalSpaces: false,
          QR_Link: false,
          Status_id: false,
          Status: true,
          Member_Number: false,
          Headline: false,
          Summary: false,
          Created_at: false,
          Updated_at: false,
        }
      },
      filterByStatus: null,
      memberStatusOptions: [
        { value: 0, label: 'No status' },
        { value: 1, label: 'Invited' },
        { value: 2, label: 'Self rejected' },
        { value: 3, label: 'Verified' },
        { value: 4, label: 'Rejected' },
        { value: 7, label: 'Requested' },
        { value: 8, label: 'Pending form' }
      ]
    };
  },
  computed: {
    placeholder() {
      return Placeholder;
    },
    enabledApps() {
      return this.$store.getters.installedApps?.data;
    },
    currentMember() {
      return this.$store.getters.loggedUser.key;
    },
    currentCollective() {
      return this.$store.getters.currentCollective?.slug ? this.$store.getters.currentCollective : null;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    enabledAppsWithExport() {
      let appsWithExports = [];
      // console.log('this.enabledApps', this.enabledApps);
      this.enabledApps?.forEach((app) => {
        // console.log('app.key', app.key);
        if(this.appsWithExport.includes(app.key)){
          appsWithExports.push({
            'key': app.key,
            'name': app.addon
          });
        }
      });
      // add tools from backoffice
      appsWithExports.push({
        'key': 'transactions',
        'name': 'Transactions'
      });
      appsWithExports.push({
        'key': 'shipments',
        'name': 'Shipments'
      });
      appsWithExports.push({
        'key': 'payments',
        'name': 'Payments'
      });
      return appsWithExports;
    }
  },
  watch: {
    //
  },
  async created() {
    await this.getInstalledApps();
  },
  methods: {
    async getInstalledApps() {
      await this.$store.dispatch('getInstalledApps');
    },
    getModelName(appNameObj) {
      return translateTranslationTable(this.currentLocale, appNameObj);
    },
    openModal(app) {
      this.$bvModal.show('export-modal');
      this.exportForm.appKey = app.key;
      this.appName = this.getModelName(app.name);
    },
    closeModal() {
      this.$bvModal.hide('export-modal');
    },
    async downloadCsvReport() {
      const connection = null; //TODO
      // Change endpoint for data-manager backend
      const endpoint = this.endpoints[this.exportForm.appKey];
      console.log('export endpoint', endpoint);
      const mainTypeID = this.modelTypes[this.exportForm.appKey];
      console.log('mainTypeID', mainTypeID);
      this.isLoadingButton = true;
      let response = null;

      
      console.log('filterByStatus', this.filterByStatus)

      if (connection === 'now' || !connection) {
        const requestConfig = {
          exportExtraData: this.exportOptions[this.exportForm.appKey],
          filterByStatus: this.filterByStatus,
          forMain: false
        };
        if (this.currentCollective.parentKey) {
          requestConfig.forSub = true;
        } else {
          requestConfig.forMain = true;
        }
        console.log('exporting basic...')
        response = await this.$store.dispatch('exportFiles', {
          itemType: endpoint,
          mainTypeID: mainTypeID,
          requestConfig: requestConfig
        });
      } else {
        console.log('exporting extended...')
        response = await this.$store.dispatch('exportFilesExtended', {
          itemType: endpoint,
          params: connection.startDate
        });
      }
      const currentDate = new Date(); 
      const timestamp = currentDate.getTime();
      let csvTitle = 'data-manager-export-' + this.exportForm.appKey;
      csvTitle += '_' + timestamp + '.csv';
      setTimeout(this.csv(response, csvTitle), 30000);  //REVIEW: this should be automatic
      this.isLoadingButton = false;
      this.closeModal();
    },
    csv(response, csvTitle) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = response.data.file;
      hiddenElement.target = '_blank';
      hiddenElement.download = csvTitle;
      hiddenElement.click();
    },
  },
};
</script>
